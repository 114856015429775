<template>
	<div class="SamplesMainDiv">
		<el-card class="box-card">
			<el-row>
				<span class="sampleTitle">Sample List</span>
				<span>-All your sample orders will be displayed here-</span>
				<p class="hr"></p>
			</el-row>
			<el-form :inline="true" size="medium">
				<el-row>
					<el-col :span="8">
						<el-form-item label="Description" label-width="125px">
							<el-input placeholder="Pls input sample name." v-model="smplName" clearable> </el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="Status">
							<el-select class="vd_smpl_select" v-model="status" placeholder="Select status of the order." clearable
							           filterable>
								<el-option class="status_select" v-for="item in statusList" :key="item.value" :label="item.label"
								           :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="Date" label-width="125px">
							<el-date-picker
									v-model="dateValue"
									type="daterange"
									align="right"
									unlink-panels
									range-separator="To"
									start-placeholder="Start Date"
									end-placeholder="End Date">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="Theme" label-width="125px">
							<el-input placeholder="Pls input sample theme." v-model="smplTheme" clearable> </el-input>
						</el-form-item>
					</el-col>
					
					<el-col :span="8">
						<el-form-item label="Account Manager">
							<el-select class="vd_smpl_select" v-model="stffName" placeholder="Select the account manager." clearable
							           filterable>
								<el-option class="status_select" v-for="item in stffList" :key="item.value" :label="item.label"
								           :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="2">&nbsp;</el-col>
				</el-row>
			</el-form>
			<div style="padding-left: 2.48vw;">
				<el-button type="primary" class="searchButton" @click="searchSmpl()" size="medium">Search</el-button>
				<el-button type="info" class="resetButton" @click="resetSearch()" size="medium">Reset</el-button>
			</div>
			<el-row class="tipRow">
				<span>Tips:</span>&nbsp;
				<i class="el-icon-remove-outline noNeedIcon">&nbsp;</i>
				<span>means this process is no need.</span>
				<i class="el-icon-circle-check notYetIcon">&nbsp;</i>
				<span>means this process hasn't started yet.</span>
				<i class="el-icon-circle-check ingIcon">&nbsp;</i>
				<span>means this process is in progress.</span>
				<i class="el-icon-circle-check finishIcon">&nbsp;</i>
				<span>means this process is complete.</span>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-table class="smplsTable" :data="tableData" border
					          :row-class-name="getRowClassName"
					          :cell-style="{padding: 0}"
					          :header-cell-style="{background: '#ffffff'}">
						<el-table-column type="expand">
							<template v-slot="scope">
								<SubTable :subTableData="scope.row.smpl_list"/>
							</template>
						</el-table-column>
						<el-table-column label="Process Single" width="200px" align="center" show-overflow-tooltip>
							<template v-slot="scope">
								<el-row>
									<!--<imgPopover v-if="scope.row.imge_url" :imgsrc="scope.row.imge_url + '.jpg'" :imgType="1"></imgPopover>-->
										<el-image
												lazy
												fit="scale-down"
												style="width: 175px; height: 175px"
												@mouseover="addImageSrcList(scope.row.imge_url, 'l')"
												:src="formatPic3(scope.row.imge_url, 'l')"
												:preview-src-list="imageSrcList">
											<div slot="error" class="image-slot">
												<span class="failedImgSpan">No Image</span>
											</div>
										</el-image>
								</el-row>
							</template>
						</el-table-column>
						<el-table-column label="Sample" min-width="160" align="center">
							<template v-slot="scope">
								<el-popover
										style="margin: 10px"
										placement="top-start"
										width="350"
										trigger="hover">
									<el-row class="picAndSmplInfo">
										<el-col :span="24" class="smplsInfoDiv">
											<el-row class="three_points">
												<span class="spanPrefix">Desc &nbsp;</span>
												<span v-if="scope.row.smpl_name_en == null" class="noData">No Data</span>
												<span class="vd_smpl_name_en" v-else>{{ scope.row.smpl_name_en }}</span>
											</el-row>
											<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Theme &nbsp;</span>
                        <span v-if="scope.row.smpl_theme != null">{{ scope.row.smpl_theme }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
											</el-row>
											<el-row class="vd_smpl_span">
												<span><span class="spanPrefix">No.&nbsp;</span>{{ scope.row.smpl_no }}</span>
											</el-row>
											<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Account Manager &nbsp;</span>
                        <span v-if="scope.row.staff_name != null">{{ scope.row.staff_name }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
											</el-row>
											<el-row class="vd_smpl_span">
												<span class="spanPrefix">Create Date&nbsp;</span>
												{{formatDate(scope.row.create_time)}}
											</el-row>
											<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Sample Quantity &nbsp;</span>
                        <span v-if="scope.row.smpl_cust_num != null">{{ scope.row.smpl_cust_num }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
											</el-row>
										</el-col>
									</el-row>
									<el-row slot="reference" class="picAndSmplInfo" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
										<el-col :span="24" class="smplsInfoDiv">
											<el-row class="three_points">
												<span class="spanPrefix">Desc &nbsp;</span>
												<span v-if="scope.row.smpl_name_en == null" class="noData">No Data</span>
												<span class="vd_smpl_name_en" v-else>{{ scope.row.smpl_name_en }}</span>
											</el-row>
											<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Theme &nbsp;</span>
                        <span v-if="scope.row.smpl_theme != null">{{ scope.row.smpl_theme }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
											</el-row>
											<el-row class="vd_smpl_span">
												<span><span class="spanPrefix">No.&nbsp;</span>{{ scope.row.smpl_no }}</span>
											</el-row>
											<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Account Manager &nbsp;</span>
                        <span v-if="scope.row.staff_name != null">{{ scope.row.staff_name }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
											</el-row>
											<el-row class="vd_smpl_span">
												<span class="spanPrefix">Create Date&nbsp;</span>
												{{formatDate(scope.row.create_time)}}
											</el-row>
											<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Sample Quantity &nbsp;</span>
                        <span v-if="scope.row.smpl_cust_num != null">{{ scope.row.smpl_cust_num }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
											</el-row>
										</el-col>
									</el-row>
								</el-popover>
							</template>
						</el-table-column>
						<el-table-column label="CAD"  min-width="55" align="center" prop="appear_status">
							<template v-slot="scope">
								<div v-for="(item,index) in scope.row.appear_status.split(',')" class="faj"
								     :style="calcDistanceBetween(scope.row.appear_status,index)">
										<i :class="addIconClass(item)" style="font-size: 28px"/>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="3D Print" min-width="55" align="center" prop="colrswat_status">
							<template v-slot="scope">
								<div v-for="(item,index) in scope.row.colrswat_status.split(',')" class="faj"
								     :style="calcDistanceBetween(scope.row.colrswat_status,index)">
									<i :class="addIconClass(item)" style="font-size: 28px"/>
								</div>
							</template>
						</el-table-column>
						<el-table-column min-width="55" align="center" prop="kind_status">
							<template #header>
								<p>Material</p>
								<p>Sample</p>
							</template>
							<template v-slot="scope">
								<div v-for="(item,index) in scope.row.kind_status.split(',')" class="faj"
								     :style="calcDistanceBetween(scope.row.kind_status,index)">
									<i :class="addIconClass(item)" style="font-size: 28px"/>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="Sample Mold" align="center">
							<el-table-column label="Tooling Started" min-width="88" align="center" prop="smpl_openmold_status">
								<template v-slot="scope">
									<div v-for="(item,index) in scope.row.kind_status.split(',')" class="faj"
									     :style="calcDistanceBetween(scope.row.kind_status,index)">
										<i :class="addIconClass(index === (scope.row.kind_status.split(',').length -1) ? scope.row.smpl_openmold_status : 2)"/>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="Tooling Finished" min-width="88" align="center" prop="smpl_moldcomp_status">
								<template v-slot="scope">
									<div v-for="(item,index) in scope.row.kind_status.split(',')" class="faj"
									     :style="calcDistanceBetween(scope.row.kind_status,index)">
										<i :class="addIconClass(index === (scope.row.kind_status.split(',').length -1) ? scope.row.smpl_moldcomp_status : 2)"/>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="Sample Finished" min-width="88" align="center" prop="smpl_delismpl_status">
								<template v-slot="scope">
									<div v-for="(item,index) in scope.row.kind_status.split(',')" class="faj"
									     :style="calcDistanceBetween(scope.row.kind_status,index)">
										<i :class="addIconClass(index === (scope.row.kind_status.split(',').length -1) ? scope.row.smpl_delismpl_status : 2)"/>
									</div>
								</template>
							</el-table-column>
						</el-table-column>
						<el-table-column label="Production Mold" align="center">
							<el-table-column label="Tooling Started" min-width="85" align="center" prop="prod_openmold_status">
								<template v-slot="scope">
									<div v-for="(item,index) in scope.row.kind_status.split(',')" class="faj"
									     :style="calcDistanceBetween(scope.row.kind_status,index)">
										<i :class="addIconClass(index === (scope.row.kind_status.split(',').length -1) ? scope.row.prod_openmold_status : 2)"/>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="Tooling Finished" min-width="88" align="center" prop="prod_moldcomp_status">
								<template v-slot="scope">
									<div v-for="(item,index) in scope.row.kind_status.split(',')" class="faj"
									     :style="calcDistanceBetween(scope.row.kind_status,index)">
										<i :class="addIconClass(index === (scope.row.kind_status.split(',').length -1) ? scope.row.prod_moldcomp_status : 2)"/>
									</div>
								</template>
							</el-table-column>
							<el-table-column label="Sample Finished" min-width="88" align="center" prop="prod_delismpl_status">
								<template v-slot="scope">
									<div v-for="(item,index) in scope.row.kind_status.split(',')" class="faj"
									     :style="calcDistanceBetween(scope.row.kind_status,index)">
										<i :class="addIconClass(index === (scope.row.kind_status.split(',').length -1) ? scope.row.prod_delismpl_status : 2)"/>
									</div>
								</template>
							</el-table-column>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
			<el-divider></el-divider>
			<el-row class="pageRow">
				<pubPagination :totalPage='totalItem' @changePageSearch="changePageSearch" ref="pubPagination"/>
			</el-row>
		</el-card>
	</div>
</template>
<script>
import {get} from "@/api/request";
import {dyjAPI} from "@/api/modules/dyj";
import pubPagination from "@/components/common/pubPagination";
import imgPopover from "@/components/imgPopover.vue"
import SubTable from "@/components/SubTable";

export default {
	name: 'CewerList',
	components: {
		SubTable,
		pubPagination,
		imgPopover
	},
	data() {
		return {
			imageSrcList: [""],
			leftVisible: false,
			rightVisible: false,
			// 获取所有样单信息的数组
			tableData: [],
			subTableData: [],
			// 订单日期
			dateValue: [],
			stffName: '',
			// 样品编号
			smplNo: '',
			// 主题
			smplTheme: '',
			// 样品名
			smplName: '',
			// 状态值
			status: '',
			// 状态下拉列表
			statusList: [
				{value: '0', label: 'In Progress'},
				{value: '1', label: 'Completed'}
			],
			// 外销人员下拉列表
			stffList: [
				{value: 'Ethan', label: 'Ethan',},
				{value: 'Yvonne', label: 'Yvonne',},
				{value: 'Roger', label: 'Roger',},
				{value: 'Ken', label: 'Ken',},
				{value: 'Jerry', label: 'Jerry',},
				{value: 'Bruce', label: 'Bruce',},
				{value: 'Bryan', label: "Bryan",},
				{value: 'Jennifer', label: 'Jennifer',},
				{value: 'Sally', label: 'Sally',},
				{value: 'Mary', label: 'Mary',},
				{value: 'Ronnie', label: "Ronnie",},
				{value: 'Grace', label: 'Grace',},
				{value: 'Heidi', label: 'Heidi',},
				{value: 'Wayne', label: 'Wayne',},
				{value: 'Joy', label: 'Joy',},
				{value: 'Diego', label: 'Diego',},
				{value: 'Amber', label: 'Amber',}
			],
			// 分页数据总数
			totalItem: 0,
			// 当前页数,默认1
			currentPage: 1,
			tableRowHeight: 0,
		};
	},
	created() {
		if (this.$route.query.smplNo) {
			this.smplNo = this.$route.query.smplNo;
			this.$router.push({query: {}});
		}
		this.initData();
	},
	mounted() {
		this.$once("hook:updated",  () => {
			this.tableRowHeight = document.getElementsByClassName('el-table__row')[0].clientHeight
		});
		document.getElementsByClassName("el-form-item__label")[1].setAttribute("style",`width:${document.getElementsByClassName("el-form-item__label")[4].offsetWidth}px`)
		window.addEventListener('resize', (event) => {
			document.getElementsByClassName("el-form-item__label")[1].setAttribute("style",`width:${document.getElementsByClassName("el-form-item__label")[4].offsetWidth}px`)
		})
	},
	watch: {
		$route(to, from) {
			if (from.path === '/home') {
				this.smplNo = this.$route.query.smplNo
				this.$router.push({query: {}});
				this.initData()
			}
		},
	},
	methods: {
		addImageSrcList(row, type) {
			if (!row) return;
			this.imageSrcList[0] = this.helper.picUrl(row, type)
		},
		// 格式化图片
		formatPic3(row, type) {
			if (!row) return;
			return this.helper.picUrl(row, type)
		},
		getRowClassName(row, rowIndex) {
			let data = row.row;
			let res = [];
			if (data.smpl_list) {
				res.push('row-expand-has')
				return res;
			} else {
				res.push('row-expand-unhas')
				return res;
			}
		},
		changeActive(val) {
			if (val) {
				this.leftVisible = true;
			} else {
				this.rightVisible = true;
			}
		},
		removeActive(val) {
			if (val) {
				this.leftVisible = false;
			} else {
				this.rightVisible = false;
			}
		},
		// 初始化方法
		initData() {
			this.getAllSmpls();
			// this.getSmplList();
		},
		// 搜索按钮
		searchSmpl() {
			this.$refs.pubPagination.currentPage = 1;
			this.changePageSearch(1);
		},
		// 重置按钮
		resetSearch() {
			this.currentPage = 1,
					this.smplName = '',
					this.smplNo = '',
					this.status = '',
					this.dateValue = '',
					this.smplTheme = '',
					this.stffName = '',
					this.$refs.pubPagination.currentPage = 1;
			this.changePageSearch(1);
		},
		// 获取所有样单信息
		getAllSmpls() {
			const timeNewVal = this.helper.getTime(this.dateValue)
			get(dyjAPI.getNpsmsFromDyj, {
				smplTheme: this.smplTheme,
				stffName: this.stffName,
				pageNo: this.currentPage,
				smplName: this.smplName,
				smplNo: this.smplNo,
				status: this.status,
				startTime: timeNewVal.startTime,
				endTime: timeNewVal.endTime,
			}).then((res) => {
				// 请求成功时
				if (res.data.code === 0) {
					this.tableData = res.data.data.list;
					this.subTableData = res.data.data.smplList;
					this.totalItem = res.data.data.totalItem;
					// 失败
				} else {
					let mg = res.data.msg;
					this.$message({message: mg});
				}
			}).catch(msg => this.$message({message: msg, type: "error"}))
		},
		// 分页查询
		changePageSearch(val) {
			this.currentPage = val
			this.getAllSmpls()
		},
		// 搜索按钮
		searchMtrl() {
			this.$refs.pubPagination.currentPage = 1;
			this.changePageSearch(1);
		},
		// 格式化图片
		formatPic(row) {
			return this.helper.picUrl(row.imge_url, 'l')
		},
		// 格式化图片
		formatPic2(url) {
			return this.helper.picUrl(url, '')
		},
		// 时间转换
		formatDate(row) {
			return this.helper.toStringDate(row)
		},
		// 动态改变图标
		addIconClass(statsID) {
			let statsNumID = Number(statsID);
			switch (statsNumID) {
				case 3:
					return "el-icon-circle-check ingIcon"
				case 2:
					return "el-icon-remove-outline notYetIcon"
				case 1:
					return "el-icon-circle-check finishIcon"
				default:
					return "el-icon-circle-check notYetIcon"
			}
		},
		calcDistanceBetween(data, index) {
			return `height:${this.tableRowHeight / data.split(',').length}px;
			border-bottom:${index !== data.split(',').length - 1 ? 1 : 0}px solid #ebeef5`;
		},
	},
};
</script>
<style>
.row-expand-unhas .el-table__expand-column{
	pointer-events: none !important;
}
.row-expand-unhas .el-table__expand-column .el-icon{
	visibility:hidden !important;
}
/* 未经历图标 */
.notYetIcon {
	color: #cacacaa6;
}
.noNeedIcon {
	color: #423d3da6;
}
/* 经历中图标 */
.ingIcon {
	color: rgb(222, 203, 0);
}
/* 已完成图标 */
.finishIcon {
	color: #5eca32;
}
.faj {
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
<style scoped>

.clearfix:before,
.clearfix:after {
	display: table;
	content: "";
}
.clearfix:after {
	clear: both
}
.box-card {
	height: 100%;
	margin: 20px 40px 0 230px;
	padding-bottom: 100px;
}
/* sampleList标题 */
.sampleTitle {
	font-size: x-large;
	color: rgb(110, 110, 110);
	float: left;
	font-weight: bolder;
	margin: 10px 10px 30px 10px;
}
/* sampleList标题后的说明 */
.sampleTitle+span {
	color: grey;
	float: left;
	font-size: 14px;
	margin: 18px 10px 20px 10px;
}
/* 分割线 */
.SamplesMainDiv .hr {
	border-bottom: 1px solid grey;
	margin-top: 55px;
}
/* 改变el时间选择器自带样式 */
.SamplesMainDiv .el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
	width: 250px !important;
}

/* 搜索按钮 */
.SamplesMainDiv .searchButton {
	font-size: 12px;
	margin-right: 20px;
}

/* 重置按钮 */
.SamplesMainDiv .resetButton {
	font-size: 12px;
}

/* 样品表 */
.smplsTable {
	margin-top: 20px;
}

/* 改变el-table表头边框 */
/* .SamplesMainDiv th.el-table_2_column_2 {
  border-right: none;
}

/* 物料信息和图片所在的row */
.picAndSmplInfo {
	/* border-right: 1px solid rgba(112, 112, 112, 0.1); */
	padding: 6px 6px 6px 2px;
	text-align: left;
}

/*  hover图片时放大 */
.SamplesMainDiv .vd_popimg {
	width: 400px;
	height: 400px;
}

/* 分页行 */
.SamplesMainDiv .pageRow {
	margin-top: 20px;
	padding-left: 40%;
}

/* 样品英文名 */
.vd_smpl_name_en {
	font-size: 15px;
	
}

/* 样品英文名下的各种信息 */
.vd_smpl_span {
	margin-top: 6px;
}

/* 各种信息前缀 */
.spanPrefix {
	font-weight: bold;
}


/* 所有勾图标的大小 */
.smplsTable .el-icon-circle-check {
	font-size: 26px !important;
}

/* 所有杠图标的大小 */
.smplsTable .el-icon-remove-outline {
	font-size: 26px;
}

/* 图标行 */
.iconRow {
	margin-top: 15px;
	margin-bottom: 10px;
}

/* 经历中图标 */
.ingIcon {
	color: rgb(222, 203, 0) !important;
}

/* 未经历图标 */
.notYetIcon {
	color: #cacacaa6;
}

.noNeedIcon {
	color: #423d3da6;
}

/* 已完成图标 */
.SamplesMainDiv .finishIcon {
	color: #5eca32;
}

/* 提示信息行 */
.SamplesMainDiv .tipRow {
	margin-top: 30px;
}

/* 提示信息里的文字 */
.SamplesMainDiv .tipRow span {
	color: grey;
	float: left;
	margin-right: 10px;
	font-size: 15px;
}

/* 提示信息里的图标 */
.SamplesMainDiv .tipRow i {
	font-size: 21px;
	float: left;
}

/* 加载失败时的占位图片 */
.SamplesMainDiv .failedImg {
	width: 100px;
	height: 100px;
	border: 1px solid rgba(112, 112, 112, 0.1);
}

.image-slot {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

/* 加载成功时的小图 */
.SamplesMainDiv .vd_elimg {
	width: 100px;
	height: 100px;
}
/* 图片所在的row */
.vd_img_row{
	padding-top: 20px;
}
/* 样品图片和信息的间距 */
.SamplesMainDiv .smplsInfoDiv {
	/*padding-left: 16px;*/
}

/* 改变EL-lable自带样式 */
.SamplesMainDiv .el-form--inline .el-form-item__label {
	width: 130px;
	text-align: right;
}

/* 文字超出部分省略 */
.three_points {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.failedImg {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.failedImgSpan {
	color: #ccc;
}

.processSheetImage {
	width: 100%;
	height: 100%;
}
/deep/.cell {
	padding: 0 !important;
}

</style>