<template>
  <div class="vg_pagination-container vg_mt_8">
    <el-pagination
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="currentPage"
        :total="totalPage"
        :page-size="pageSize"
        layout=" total,prev, pager, next">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "pubPagination",
  data(){
    return{
      currentPage:1,
    }
  },
  props:{
    totalPage: Number,
    pageSize : {
      type:Number,
      default:10
    }
  },
  methods:{
    childMethod() {
      this.$emit('changePageSearch',this.currentPage)
    },
    sizeChangeHandle(){},
    currentChangeHandle(val){
      this.currentPage = val
      this.childMethod()
    }
  },


}
</script>

<style scoped lang="scss">

</style>