<template>
  <div>
    <el-popover  placement="right" width="400" trigger="hover">
      <div class="vd_div">
        <img class="vd_popimg" :src="formatPic(imgsrc, 'l')" @load="imgloadCopy"/>
        <div class="vd_posi" v-if="!bigfalg">
          <i class="el-icon-loading vd_icon_size"></i>
        </div>
      </div>
      <div slot="reference">
        <img :class="[imgType===1?'vd_elimg_new':'vd_elimg']" :src="formatPic(imgsrc, 's')" fit="fill" width="175px" lazy @load="imgload">
        <div class="vd_posi" v-if="!falg">
          <i class="el-icon-loading vd_icon_size_o"></i>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  name:"imgPopover",
  props:{
    imgsrc:{type:String,required:true},
    imgType:{type:Number,required:true},
  },
  data(){
    return{
      falg:false,
      bigfalg:false
    }
  },
  methods:{
    // 格式化图片
		formatPic(row, size) {
			// let updateTime = JSON.parse(JSON.stringify(new Date().getTime()))
      if(this.imgType === 0){
        return this.helper.picUrl(row, size)
      }else if(this.imgType === 1){
        return this.helper.picUrl(row)
      }
			
		},
    // 图片加载成功
    imgload(e){
      this.falg = true
    },
    // 图片加载成功
    imgloadCopy(e){
      this.bigfalg = true
    },
  }
}
</script>

<style scoped>
  /*  hover图片时放大 */
.vd_popimg {
	width: 400px;
	height: 400px;
}
/* 加载成功时的小图 */
.vd_elimg {
	width: 100px !important;
	height: 100px !important;
	margin-top: 6px;
}
.vd_elimg_new {
	width: 175px !important;
	height: 175px !important;
	margin-top: 6px;
}
.vd_div{
  width: 100%;
  height: auto;
  position: relative;
}
.vd_posi{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}
.vd_icon_size{
  font-size: 50px;
  color: #409eff;
}
.vd_icon_size_o{
  font-size: 30px;
  color: #409eff;
}
</style>