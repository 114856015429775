<template>
	<el-card>
		<el-row>
			<el-col :span="24">
				<el-table :data="subTableData" style="width: 100%;" border :cell-style="{padding:0}">
					<el-table-column label="Process Single" width="200px" align="center" show-overflow-tooltip>
						<template v-slot="scope">
							<el-row>
								<imgPopover v-if="scope.row.craft_imge_url" :imgsrc="scope.row.craft_imge_url"  :imgType="1"></imgPopover>
								<div v-else>
									<el-image>
										<div slot="error" class="image-slot">
											<span class="failedImgSpan">No Image</span>
										</div>
									</el-image>
								</div>
							</el-row>
						</template>
					</el-table-column>
					<el-table-column label="Sample" width="350px" align="center">
						<template v-slot="scope">
							<el-popover
									placement="top-start"
									width="350"
									trigger="hover">
								<el-row class="picAndSmplInfo">
									<el-col :span="24" class="smplsInfoDiv" align="left">
										<el-row class="three_points">
											<span class="spanPrefix">Desc &nbsp;</span>
											<span v-if="scope.row.smpl_name_en === null" class="noData">No Data</span>
											<span class="vd_smpl_name_en" v-else>{{ scope.row.smpl_name_en }}</span>
										</el-row>
										<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Theme &nbsp;</span>
                        <span v-if="scope.row.smpl_theme !== null">{{ scope.row.smpl_theme }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
										</el-row>
										<el-row class="vd_smpl_span">
											<span><span class="spanPrefix">No.&nbsp;</span>{{ scope.row.smpl_no }}</span>
										</el-row>
										<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Account Manager &nbsp;</span>
                        <span v-if="scope.row.staff_name != null">{{ scope.row.staff_name }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
										</el-row>
										<el-row class="vd_smpl_span">
                      <span><span class="spanPrefix">Create Date&nbsp;</span>{{
		                      formatDate(scope.row.create_time)
	                      }}</span>
										</el-row>
										<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Sample Quantity &nbsp;</span>
                        <span v-if="scope.row.smpl_cust_num != null">{{ scope.row.smpl_cust_num }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
										</el-row>
									</el-col>
								</el-row>
								<el-row slot="reference" class="picAndSmplInfo" style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
									<el-col :span="8" style="display: flex">
										<el-row class="vd_img_row">
											<imgPopover v-if="scope.row.imge_url" :imgsrc="scope.row.imge_url"  :imgType="0"></imgPopover>
											<div v-else>
												<el-image class="img failedImg">
													<div slot="error" class="image-slot">
														<span class="failedImgSpan">No Image</span>
													</div>
												</el-image>
											</div>
										</el-row>
									</el-col>
									<el-col :span="16" class="smplsInfoDiv" align="left">
										<el-row class="three_points">
											<span class="spanPrefix">Desc &nbsp;</span>
											<span v-if="scope.row.smpl_name_en === null" class="noData">No Data</span>
											<span class="vd_smpl_name_en" v-else>{{ scope.row.smpl_name_en }}</span>
										</el-row>
										<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Theme &nbsp;</span>
                        <span v-if="scope.row.smpl_theme !== null">{{ scope.row.smpl_theme }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
										</el-row>
										<el-row class="vd_smpl_span">
											<span><span class="spanPrefix">No.&nbsp;</span>{{ scope.row.smpl_no }}</span>
										</el-row>
										<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Account Manager &nbsp;</span>
                        <span v-if="scope.row.staff_name != null">{{ scope.row.staff_name }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
										</el-row>
										<el-row class="vd_smpl_span">
                      <span><span class="spanPrefix">Create Date&nbsp;</span>{{
		                      formatDate(scope.row.create_time)
	                      }}</span>
										</el-row>
										<el-row class="vd_smpl_span">
                      <span>
                        <span class="spanPrefix">Sample Quantity &nbsp;</span>
                        <span v-if="scope.row.smpl_cust_num != null">{{ scope.row.smpl_cust_num }}</span>
                        <span v-else class="noData">No Data</span>
                      </span>
										</el-row>
									</el-col>
								</el-row>
							</el-popover>
						</template>
					</el-table-column>
					<!-- 分割线，下面是工序步骤内容 -->
					<el-table-column align="center" label="Step">
						<template v-slot="scope">
							<el-row>
								<el-col style="width: 8.9819%">
									<el-row class="three_points">
										<el-popover placement="top-start" width="150px"  trigger="hover"
										            content="Pattern Making">
											<el-row slot="reference">Pattern</el-row>
											<el-row slot="reference">Making</el-row>
										</el-popover>
									</el-row>
									<el-row class="iconRow">
										<i :class="addIconClass(scope.row.open_status)"></i>
									</el-row>
								</el-col>
								<el-col style="width: 8.9819%">
									<el-row class="three_points">
										<el-popover placement="top-start" width="150px"  trigger="hover"
										            content="Material Preparing">
											<el-row slot="reference">Material</el-row>
											<el-row slot="reference">Preparing</el-row>
										</el-popover>
									</el-row>
									<el-row class="iconRow">
										<i :class="addIconClass(scope.row.stpr_status)"></i>
									</el-row>
								</el-col>
								<el-col style="width: 8.9819%">
									<el-row class="three_points">
										<el-popover placement="top-start" width="150px"  trigger="hover"
										            content="Lining">
											<el-row slot="reference" >Lining</el-row>
										</el-popover>
									</el-row>
									<el-row>&nbsp;</el-row>
									<el-row class="iconRow">
										<i :class="addIconClass(scope.row.comp_status)"></i>
									</el-row>
								</el-col>
								<el-col style="width: 8.9819%">
									<el-row class="three_points">
										<el-popover placement="top-start" width="150px"  trigger="hover"
										            content="Cutting">
											<el-row slot="reference">Cutting</el-row>
										</el-popover>
									</el-row>
									<el-row>&nbsp;</el-row>
									<el-row class="iconRow">
										<i :class="addIconClass(scope.row.lase_status)"></i>
									</el-row>
								</el-col>
								<el-col style="width: 8.9819%">
									<el-row class="three_points">
										<el-popover placement="top-start" width="150px"  trigger="hover"
										            content="Printing">
											<el-row slot="reference">Printing</el-row>
										</el-popover>
									</el-row>
									<el-row>&nbsp;</el-row>
									<el-row class="iconRow">
										<i :class="addIconClass(scope.row.stam_status)"></i>
									</el-row>
								</el-col>
								<el-col style="width: 8.9819%">
									<el-row class="three_points">
										<el-popover placement="top-start" width="150px"  trigger="hover"
										            content="Embroidery">
											<el-row slot="reference">Embroidery</el-row>
										</el-popover>
									</el-row>
									<el-row>&nbsp;</el-row>
									<el-row class="iconRow">
										<i :class="addIconClass(scope.row.embr_status)"></i>
									</el-row>
								</el-col>
								<el-col style="width: 8.9819%">
									<el-row class="three_points">
										<el-popover placement="top-start" width="150px"  trigger="hover"
										            content="Machine Sewing">
											<el-row slot="reference">Machine </el-row>
											<el-row slot="reference">Sewing</el-row>
										</el-popover>
									</el-row>
									<el-row class="iconRow">
										<i :class="addIconClass(scope.row.mase_status)"></i>
									</el-row>
								</el-col>
								<el-col style="width: 8.9819%">
									<el-row class="three_points">
										<el-popover placement="top-start" width="150px"  trigger="hover"
										            content="Hand Sewing">
											<el-row slot="reference">Hand</el-row>
											<el-row slot="reference">Sewing</el-row>
										</el-popover>
									</el-row>
									<el-row class="iconRow">
										<i :class="addIconClass(scope.row.manu_status)"></i>
									</el-row>
								</el-col>
								<el-col style="width: 8.9819%">
									<el-row class="three_points">
										<el-popover placement="top-start" width="150px"  trigger="hover"
										            content="Sample Checking">
											<el-row slot="reference">Sample</el-row>
											<el-row slot="reference">Checking</el-row>
										</el-popover>
									</el-row>
									<el-row class="iconRow">
										<i :class="addIconClass(scope.row.insp_status)"></i>
									</el-row>
								</el-col>
								<el-col style="width: 8.9819%">
									<el-row class="three_points">
										<el-popover placement="top-start" width="150px"  trigger="hover"
										            content="Sample Finished">
											<el-row slot="reference">Sample</el-row>
											<el-row slot="reference">Finished</el-row>
										</el-popover>
									</el-row>
									<el-row class="iconRow">
										<i v-if="scope.row.inspect === 1" class="el-icon-circle-check finishIcon"></i>
										<i v-else class="el-icon-circle-check notYetIcon"></i>
									</el-row>
								</el-col>
								<el-col style="width: 8.9819%">
									<el-row class="three_points">
										<el-popover placement="top-start" width="150px"  trigger="hover"
										            content="Ship">
											<el-row slot="reference">Ship</el-row>
										</el-popover>
									</el-row>
									<el-row>&nbsp;</el-row>
									<el-row class="iconRow">
										<i v-if="scope.row.post === '1'" class="el-icon-circle-check finishIcon"></i>
										<i v-else class="el-icon-circle-check notYetIcon"></i>
									</el-row>
								</el-col>
							</el-row>
						</template>
					</el-table-column>
				</el-table>
			</el-col>
		</el-row>
	</el-card>
</template>

<script>
import imgPopover from "@/components/imgPopover";

export default {
	name: "SubTable",
	components: {
		imgPopover
	},
	props: {
		subTableData: {
			type: Array,
			default: []
		},
	},
	methods: {
		// 动态改变图标
		addIconClass(statsID) {
			if (statsID === 1) {
				return "el-icon-circle-check notYetIcon"
			} else if (statsID === 0) {
				return "el-icon-remove-outline noNeedIcon"
			} else if (statsID === 2) {
				return "el-icon-circle-check ingIcon"
			} else {
				return "el-icon-circle-check finishIcon"
			}
		},
		// 时间转换
		formatDate(row) {
			return this.helper.toStringDate(row)
		},
	},
}
</script>

<style>
.el-table__expanded-cell {
	padding: 4px !important;
}
.clearfix:before,
.clearfix:after {
	display: table;
	content: "";
}

.clearfix:after {
	clear: both
}

.box-card {
	height: 100%;
	margin: 20px 40px 0 230px;
	padding-bottom: 100px;
}

/* sampleList标题 */
.sampleTitle {
	font-size: x-large;
	color: rgb(110, 110, 110);
	float: left;
	font-weight: bolder;
	margin: 10px 10px 30px 10px;
}

/* sampleList标题后的说明 */
.sampleTitle+span {
	color: grey;
	float: left;
	font-size: 14px;
	margin: 18px 10px 20px 10px;
}

/* 分割线 */
.SamplesMainDiv .hr {
	border-bottom: 1px solid grey;
	margin-top: 55px;
}

/* 改变el时间选择器自带样式 */
.SamplesMainDiv .el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
	width: 250px !important;
}

/* 搜索按钮 */
.SamplesMainDiv .searchButton {
	font-size: 12px;
	margin-right: 20px;
}

/* 重置按钮 */
.SamplesMainDiv .resetButton {
	font-size: 12px;
}

/* 样品表 */
.smplsTable {
	margin-top: 20px;
}

/* 改变el-table表头边框 */
/* .SamplesMainDiv th.el-table_2_column_2 {
  border-right: none;
}

/* 物料信息和图片所在的row */
.picAndSmplInfo {
	/* border-right: 1px solid rgba(112, 112, 112, 0.1); */
	padding: 6px 6px 6px 2px;
}

/*  hover图片时放大 */
.SamplesMainDiv .vd_popimg {
	width: 400px;
	height: 400px;
}

/* 分页行 */
.SamplesMainDiv .pageRow {
	margin-top: 20px;
	padding-left: 40%;
}

/* 样品英文名 */
.vd_smpl_name_en {
	font-size: 15px;
	
}

/* 样品英文名下的各种信息 */
.vd_smpl_span {
	margin-top: 6px;
}

/* 各种信息前缀 */
.spanPrefix {
	font-weight: bold;
}


/* 所有勾图标的大小 */
.smplsTable .el-icon-circle-check {
	font-size: 26px;
}

/* 所有杠图标的大小 */
.smplsTable .el-icon-remove-outline {
	font-size: 26px;
}

/* 图标行 */
.iconRow {
	margin-top: 15px;
	margin-bottom: 10px;
}

/* 经历中图标 */
.ingIcon {
	color: rgb(222, 203, 0);
}

/* 未经历图标 */
.notYetIcon {
	color: #cacacaa6;
}

.noNeedIcon {
	color: #423d3da6;
}

/* 已完成图标 */
.SamplesMainDiv .finishIcon {
	color: #5eca32;
}

/* 提示信息行 */
.SamplesMainDiv .tipRow {
	margin-top: 30px;
}

/* 提示信息里的文字 */
.SamplesMainDiv .tipRow span {
	color: grey;
	float: left;
	margin-right: 10px;
	font-size: 15px;
}

/* 提示信息里的图标 */
.SamplesMainDiv .tipRow i {
	font-size: 21px;
	float: left;
}

/* 加载失败时的占位图片 */
.SamplesMainDiv .failedImg {
	width: 100px;
	height: 100px;
	border: 1px solid rgba(112, 112, 112, 0.1);
}

.image-slot {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

/* 加载成功时的小图 */
.SamplesMainDiv .vd_elimg {
	width: 100px;
	height: 100px;
}
/* 图片所在的row */
.vd_img_row{
	padding-top: 20px;
}
/* 图片所在的row */
.vd_img_row{
	padding-top: 20px;
}
/* 样品图片和信息的间距 */
.SamplesMainDiv .smplsInfoDiv {
	padding-left: 16px;
}

/* 改变EL-lable自带样式 */
.SamplesMainDiv .el-form--inline .el-form-item__label {
	text-align: right;
}

/* 文字超出部分省略 */
.three_points {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.failedImg {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}
.failedImgSpan {
	color: #ccc;
}

.processSheetImage {
	width: 100%;
	height: 100%;
}
</style>